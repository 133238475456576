import React from "react";
import PropTypes from "prop-types";

import {
  defaultProps,
  externalProps,
  infoBlockItemWrapper,
  internalProps,
} from "@skryv/core-react/src/components/base/InfoBlock/InfoBlockItem";
import contextType from "@skryv/core-react/src/services/contextTypes";

class CustomInfoBlockItem extends React.Component {
  static propTypes = {
    ...externalProps,
    ...internalProps,
    item: PropTypes.shape({
      label: PropTypes.string,
      nested: PropTypes.bool,
      truncate: PropTypes.bool,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element,
        PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
              PropTypes.element,
            ]),
            truncate: PropTypes.bool,
          }),
        ),
      ]),
    }),
    columnsNumber: PropTypes.number,
  };
  static defaultProps = defaultProps;
  static contextType = contextType;

  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  handleHover(state) {
    const maxLength = this.props.item.nested ? 20 : 35;
    if (this.props.item.value.length > maxLength) {
      this.setState({ show: state });
    }
  }

  render() {
    return (
      <div
        className={`vl-u-position-relative vl-col--1-${this.props.columnsNumber} vl-col--1-${this.props.columnsNumber}--s vl-col--1-${this.props.columnsNumber}--xs`}
        onMouseEnter={() => this.handleHover(true)}
        onMouseLeave={() => this.handleHover(false)}
      >
        {this.props.item.nested ? (
          <>
            <dt className="vl-description-data__label">
              {this.context.gettext(this.props.item.label)}
            </dt>
            <dd>
              {this.props.item.value.map((nestedItem) => (
                <NestedInfoBlockItem
                  key={nestedItem.label}
                  nestedItem={nestedItem}
                />
              ))}
            </dd>
          </>
        ) : (
          <>
            <dt className="vl-description-data__label">
              {this.context.gettext(this.props.item.label)}
            </dt>
            <dd className="vl-description-data__value">
              {this.context.gettext(
                this.props.item.truncate
                  ? mapValue(this.props.item.value, 35)
                  : this.props.item.value,
              )}
            </dd>
            {this.props.item.value.length > 35 &&
              this.props.item.truncate &&
              this.state.show && (
                <div
                  style={{
                    position: "absolute",
                    top: "10",
                    fontSize: "50%",
                    backgroundColor: "white",
                    border: "1px solid black",
                    padding: "2px",
                  }}
                >
                  <p>{this.props.item.value}</p>
                </div>
              )}
          </>
        )}
      </div>
    );
  }
}

class NestedInfoBlockItem extends React.Component {
  static propTypes = {
    ...externalProps,
    ...internalProps,
    nestedItem: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element,
      ]),
      truncate: PropTypes.bool,
    }),
  };

  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  render() {
    return (
      <div
        key={this.props.nestedItem.label}
        className="vl-u-position-relative vl-form__row--addon"
        onMouseEnter={() => this.setState({ show: true })}
        onMouseLeave={() => this.setState({ show: false })}
      >
        <p style={{ marginRight: "1em" }}>{this.props.nestedItem.label}:</p>
        <p className="vl-description-data__value">
          {this.props.nestedItem.truncate
            ? mapValue(this.props.nestedItem.value, 20)
            : this.props.nestedItem.value}
        </p>
        {this.props.nestedItem.truncate &&
          this.props.nestedItem.value.length > 20 &&
          this.state.show && (
            <div
              style={{
                position: "absolute",
                top: "10",
                fontSize: "50%",
                backgroundColor: "white",
                border: "1px solid black",
                padding: "2px",
              }}
            >
              <p>{this.props.nestedItem.value}</p>
            </div>
          )}
      </div>
    );
  }
}

function mapValue(value, maxLength) {
  if (value.length > maxLength) {
    return value.substring(0, maxLength + 1) + "...";
  }

  return value;
}

export default infoBlockItemWrapper(CustomInfoBlockItem);
