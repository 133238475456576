import React from "react";

import {
  defaultProps,
  externalProps,
  internalProps,
  tableWrapper,
} from "@skryv/core-react/src/components/base/Table/Table";
import contextType from "@skryv/core-react/src/services/contextTypes";
import PropTypes from "prop-types";
import { CustomTableContent } from "./CustomTableContent";
import { getSubdossiersFromHoofddossier } from "../../services/hoofddossier";

import "./CustomTable.scss";

class Table extends React.Component {
  static propTypes = {
    ...externalProps,
    ...internalProps,
    pageable: PropTypes.shape({
      pageNumber: PropTypes.number,
      pageSize: PropTypes.number,
    }),
    size: PropTypes.number,
    totalPages: PropTypes.number,
    hoofddossierId: PropTypes.string,
  };
  static defaultProps = defaultProps;
  static contextType = contextType;

  constructor(props) {
    super(props);

    this.state = {
      tableData: this.props.tableData,
      pageable: this.props.pageable,
      size: this.props.size,
      totalPages: this.props.totalPages,
    };
  }

  handlePreviousClick = () => {
    getSubdossiersFromHoofddossier(
      this.props.hoofddossierId,
      this.state.pageable.pageNumber - 1,
      this.state.size,
    ).then((page) =>
      this.setState({
        tableData: page.content,
        pageable: page.pageable,
        size: page.size,
        totalPages: page.totalPages,
      }),
    );
  };

  handleNextClick = () => {
    getSubdossiersFromHoofddossier(
      this.props.hoofddossierId,
      this.state.pageable.pageNumber + 1,
      this.state.size,
    ).then((page) =>
      this.setState({
        tableData: page.content,
        pageable: page.pageable,
        size: page.size,
        totalPages: page.totalPages,
      }),
    );
  };

  handleChange = (newSize) => {
    if (newSize >= 5) {
      getSubdossiersFromHoofddossier(
        this.props.hoofddossierId,
        this.state.pageable.pageNumber,
        newSize,
      ).then((page) =>
        this.setState({
          tableData: page.content,
          pageable: page.pageable,
          size: page.size,
          totalPages: page.totalPages,
        }),
      );
    } else {
      this.setState({
        size: 5,
      });
    }
  };

  render() {
    const noDataContent = () => (
      <div className="vl-data-table--empty">
        {this.props.noDataText
          ? this.props.noDataText
          : this.context.gettext(defaultProps.noDataText)}
      </div>
    );

    return (
      <div className="skryv-table">
        {!this.state.tableData || !this.props.tableColumns ? (
          noDataContent()
        ) : (
          <>
            {this.props.filterTableAction && (
              <div className="vl-search-wrapper">
                <i className="vl-vi vl-vi-magnifier" aria-hidden="true"></i>
                <input
                  id="vl-search-bar"
                  name="vl-search-bar"
                  type="text"
                  placeholder={
                    this.props.placeholderForSearchField ||
                    this.context.gettext("Search")
                  }
                  onChange={this.props.filterTableAction}
                  className="vl-input-field vl-input-field--block"
                />
              </div>
            )}

            <table className="vl-data-table vl-data-table--hover vl-data-table--collapsed-s">
              <thead>
                <tr>
                  {this.props.tableColumns.map((column) => (
                    <th
                      key={column.key}
                      data-title={this.context.gettext(column.text)}
                    >
                      {this.context.gettext(column.text)}
                    </th>
                  ))}
                </tr>
              </thead>
              {this.state.tableData.length > 0 && (
                <tbody>
                  <CustomTableContent
                    tableData={this.state.tableData}
                    tableColumns={this.props.tableColumns}
                    itemClickAction={this.props.itemClickAction}
                    renderCell={this.props.renderCell}
                    gettext={this.context.gettext}
                    pageable={this.state.pageable}
                    totalPages={this.state.totalPages}
                  />
                </tbody>
              )}
            </table>
            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="flexBox justifyEnd">
                <p className="vl-u-spacer-right--small">
                  {this.context.gettext("Toon per")}
                </p>
                <input
                  onChange={(event) => this.handleChange(event.target.value)}
                  type="number"
                  value={this.state.size}
                />
              </div>
            )}
            {this.state.tableData && this.state.tableData.length > 0 && (
              <div
                className={`flexBox ${this.state.pageable.pageNumber === 0 ? "justifyEnd" : "justifyBetween"}`}
              >
                {this.state.pageable.pageNumber !== 0 && (
                  <div className="vl-pager__element">
                    <button
                      className="vl-pager__element__cta vl-link vl-link--bold"
                      onClick={() => this.handlePreviousClick()}
                    >
                      <i className="vl-link--icon vl-link__icon--before vl-vi vl-vi-arrow-left-fat"></i>
                      {this.context.gettext("Vorige")}
                    </button>
                  </div>
                )}
                {this.state.pageable.pageNumber !==
                  this.state.totalPages - 1 && (
                  <div className="vl-pager__element">
                    <button
                      className="vl-pager__element__cta vl-link vl-link--bold"
                      onClick={() => this.handleNextClick()}
                    >
                      {this.context.gettext("Volgende")}
                      <i className="vl-link--icon vl-link__icon--before vl-vi vl-vi-arrow-right-fat"></i>
                    </button>
                  </div>
                )}
              </div>
            )}
            {this.state.tableData.length === 0 && noDataContent()}
          </>
        )}
      </div>
    );
  }
}

export default tableWrapper(Table);
