import { debounce, map } from "lodash";
import React, { useContext, useEffect, useState } from "react";

import useAddress, {
  containerComponentProps,
} from "@skryv/core-react/src/components/form/editor/AddressInput/addressInputHelpers";
import { TranslationsContext } from "@skryv/core-react/src/services/translations";

import Text from "@skryv/core-react-vo/src/components/base/InputFields/Text/Text";

import "./AddressWithGemeenteParameters.scss";

import Autocomplete from "@skryv/core-react-vo/src/components/base/InputFields/Autocomplete/Autocomplete";
import InputField from "@skryv/core-react-vo/src/components/base/InputFields/InputField/InputField";

import EditorComponent from "@skryv/core-react-vo/src/components/form/components/wrappers/EditorComponent/EditorComponent";

function AddressWithGemeenteParameters(props) {
  const {
    id,
    autocomplete,
    fields = {},
    getAddressForQuery,
    label,
    isRequired,
    readOnly,
    helpInLine,
    help,
    errorMessages,
    warningMessages,
  } = useAddress(props, EditorComponent);

  const isActief = props.manipulator.propertyManipulators.isActief;
  const nisCode = props.manipulator.propertyManipulators.nisCode;
  const vermeldingOnderzoek =
    props.manipulator.propertyManipulators.vermeldingOnderzoek;
  const geindexeerdBedragWoning =
    props.manipulator.propertyManipulators.geindexeerdBedragWoning;
  const geindexeerdBedragKamer =
    props.manipulator.propertyManipulators.geindexeerdBedragKamer;

  const { gettext } = useContext(TranslationsContext);
  const [autocompleteQuery, setAutocompleteQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");
  const [errorCode, setErrorCode] = useState(undefined);

  function shouldShowSearchField() {
    return autocomplete && !fields.street.readOnly;
  }

  // when one of the fields is changed manually, clear the query
  useEffect(() => {
    setAutocompleteQuery("");
  }, [
    fields.street?.value,
    fields.housenumber?.value,
    fields.boxnumber?.value,
    fields.zipcode?.value,
    fields.municipality?.value,
    fields.coordinates?.value,
  ]);

  const retrieveGemeenteParameters =
    props.config.customRequest.retrieveGemeenteParameters;

  useEffect(() => {
    function clearGemeenteParameters() {
      isActief.clear();
      vermeldingOnderzoek.clear();
      geindexeerdBedragWoning.clear();
      geindexeerdBedragKamer.clear();
      nisCode.clear();
    }

    setError("");
    setErrorCode(undefined);
    clearGemeenteParameters();

    if (!fields.zipcode?.value || fields.zipcode?.value?.length !== 4) {
      return;
    }

    setIsFetching(true);
    retrieveGemeenteParameters(fields.zipcode.value, props.dossierType)
      .then((gemeenteParameters) => {
        isActief.value = gemeenteParameters.actief;
        vermeldingOnderzoek.value = gemeenteParameters.vermeldingOnderzoek;
        geindexeerdBedragWoning.value =
          gemeenteParameters.geindexeerdBedragWoning;
        geindexeerdBedragKamer.value =
          gemeenteParameters.geindexeerdBedragKamer;
        nisCode.value = gemeenteParameters.nisCode;
        setIsFetching(false);
      })
      .catch((error) => {
        console.error("Error while fetching gemeente parameters", error.data);
        setError(error.data.message);
        setErrorCode(error.data.code);
        setIsFetching(false);
      });
  }, [
    fields.zipcode?.value,
    isActief,
    vermeldingOnderzoek,
    geindexeerdBedragWoning,
    geindexeerdBedragKamer,
    nisCode,
    props.dossierType,
    retrieveGemeenteParameters,
  ]);

  function showSearchField() {
    return (
      shouldShowSearchField() && (
        <div className=" editor-component vl-col--12-12 editor-component-first-in-row">
          <div className="autocomplete">
            <Autocomplete
              id={`${id}-autocomplete`}
              value={autocompleteQuery}
              onChange={onAutocompleteQueryChange}
              onOptionSelection={setResult}
              options={searchResults}
              nested={true}
              placeholder={gettext("Dorpsstraat 1, 9999 Vredegem")}
            />
          </div>
        </div>
      )
    );
  }

  function onAutocompleteQueryChange(query) {
    setAutocompleteQuery(query);
    // safety check to see if getAddressForQuery function is passed via the form configuration
    if (getAddressForQuery) {
      debounce(() => {
        getAddressForQuery(query).then((searchResults) => {
          const mappedSearchResults = map(searchResults, (searchResult) => {
            return {
              ...searchResult,
              label: searchResult.formattedAddress,
              key: searchResult.formattedAddress,
            };
          });
          setSearchResults(mappedSearchResults);
        });
      }, 1000)();
    } else {
      console.error(
        "The form is not setup to use autocomplete on an address input field, please pass the getAddressForQuery function via the form configuration",
      );
      return;
    }
  }

  function setResult(result) {
    const {
      street,
      housenumber,
      boxnumber,
      zipcode,
      municipality,
      coordinates,
    } = result;
    if (boxnumber) {
      fields.boxnumber.onChange(boxnumber);
    }
    if (housenumber) {
      fields.housenumber.onChange(housenumber);
    }
    if (street) {
      fields.street.onChange(street);
    }
    if (municipality) {
      fields.municipality.onChange(municipality);
    }
    if (zipcode) {
      fields.zipcode.onChange(zipcode);
    }
    if (coordinates && fields.coordinates) {
      fields.coordinates.long.onChange(coordinates.long);
      fields.coordinates.lat.onChange(coordinates.lat);
      fields.coordinates.x.onChange(coordinates.x);
      fields.coordinates.y.onChange(coordinates.y);
    }
    setSearchResults([]);
  }

  function buildDefaultTextInput(fieldName) {
    const field = fields[fieldName];
    return (
      <Text
        id={field.id}
        name={field.id}
        label={gettext(field.label)}
        value={field.value}
        onChange={field.onChange}
        type="text"
        isRequired={field.isRequired}
        helpInLine={gettext(field.helpInLine)}
        help={gettext(field.help)}
        readOnly={field.readOnly}
        errorMessages={field.errorMessages}
        warningMessages={field.warningMessages}
        nested={true}
      />
    );
  }

  function renderEditableInput() {
    return (
      <div className="skr-fieldset skr-address vl-grid vl-grid--align-start vl-grid--v-bottom">
        {shouldShowSearchField() ? showSearchField() : null}
        <div className="vl-col--8-12 vl-col--12-12--m vl-col--12-12--m editor-component-first-in-row">
          {fields.street.component
            ? fields.street.component()
            : buildDefaultTextInput("street")}
        </div>
        <div className="vl-col--2-12 vl-col--6-12--m editor-component-first-in-row--sm">
          {fields.housenumber.component
            ? fields.housenumber.component()
            : buildDefaultTextInput("housenumber")}
        </div>
        <div className="vl-col--2-12 vl-col--6-12--m">
          {fields.boxnumber.component
            ? fields.boxnumber.component()
            : buildDefaultTextInput("boxnumber")}
        </div>
        <div
          className={
            "vl-col--4-12 vl-col--6-12--m vl-col--12-12--s editor-component-first-in-row"
          }
        >
          {fields.zipcode.component
            ? fields.zipcode.component()
            : buildDefaultTextInput("zipcode")}
        </div>
        <div className={"vl-col--8-12 vl-col--6-12--m vl-col--12-12--s"}>
          {fields.municipality.component
            ? fields.municipality.component()
            : buildDefaultTextInput("municipality")}
        </div>
        {fields.coordinates && (
          <>
            <div className={"vl-col--3-12 vl-col--6-12--m vl-col--6-12--s"}>
              {fields.coordinates.long.component
                ? fields.coordinates.long.component()
                : buildDefaultTextInput("long")}
            </div>
            <div className={"vl-col--3-12 vl-col--6-12--m vl-col--6-12--s"}>
              {fields.coordinates.lat.component
                ? fields.coordinates.lat.component()
                : buildDefaultTextInput("lat")}
            </div>
            <div className={"vl-col--3-12 vl-col--6-12--m vl-col--6-12--s"}>
              {fields.coordinates.x.component
                ? fields.coordinates.x.component()
                : buildDefaultTextInput("x")}
            </div>
            <div className={"vl-col--3-12 vl-col--6-12--m vl-col--6-12--s"}>
              {fields.coordinates.y.component
                ? fields.coordinates.y.component()
                : buildDefaultTextInput("y")}
            </div>
          </>
        )}
        {fields.zipcode.value &&
          fields.zipcode.value.length === 4 &&
          !isFetching &&
          !error &&
          !isActief.value && (
            <div className="inline-errors">
              <p>
                <span className="vl-form__error vl-u-mark vl-u-mark--error">
                  De gemeente waar het pand zich in bevindt ondersteunt nog geen
                  online aanvragen via dit loket. Gelieve uw aanvraag van het
                  {" " + props.dossierType} aan het gemeenteloket te doen.
                </span>
              </p>
            </div>
          )}
        {!isFetching && error && (
          <div className="inline-errors">
            <p>
              {!errorCode ||
                (errorCode !== 400 && (
                  <span className="vl-form__error vl-u-mark vl-u-mark--error">
                    Het systeem kan op dit moment niet nagaan of het pand
                    waarvoor u een aanvraag wil indienen, in een gemeente ligt
                    die de aanvraag ondersteunt. Probeer het later opnieuw.
                  </span>
                ))}
              {errorCode && errorCode === 400 && (
                <span className="vl-form__error vl-u-mark vl-u-mark--error">
                  Deze postcode bestaat niet.
                </span>
              )}
            </p>
          </div>
        )}
      </div>
    );
  }

  function readOnlyString() {
    const streetValue = fields.street.value;
    const housenumberValue = fields.housenumber.value;
    const boxnumberValue = fields.boxnumber.value;
    const zipcodeValue = fields.zipcode.value;
    const municipalityValue = fields.municipality.value;

    const hasFistRow = !!streetValue || !!housenumberValue || !!boxnumberValue;
    const hasSecondRow = !!zipcodeValue || !!municipalityValue;

    const streetText = streetValue || "";
    const housenumberText = housenumberValue
      ? streetValue
        ? " " + housenumberValue
        : housenumberValue
      : "";
    const boxnumberText = boxnumberValue
      ? streetValue || housenumberValue
        ? " / " + boxnumberValue
        : "/ " + boxnumberValue
      : "";
    streetValue && boxnumberValue ? " / " + boxnumberValue : "";
    const zipcodeText = zipcodeValue || "";
    const municipalityText = municipalityValue
      ? zipcodeValue
        ? " " + municipalityValue
        : municipalityValue
      : "";

    return `${streetText}${housenumberText}${boxnumberText}${
      hasFistRow && hasSecondRow ? "\n" : ""
    }${zipcodeText}${municipalityText}`;
  }

  function renderReadOnlyInput() {
    const allFieldsEmpty = !(
      fields.street.value ||
      fields.housenumber.value ||
      fields.boxnumber.value ||
      fields.zipcode.value ||
      fields.municipality.value
    );
    return !allFieldsEmpty ? (
      <p className="vl-form__annotation read-only">
        <span className="allow-linebreaks">{readOnlyString()}</span>
      </p>
    ) : (
      <p className="vl-form__annotation">{gettext("n/a")}</p>
    );
  }

  return (
    <InputField
      id={id}
      label={label}
      isRequired={isRequired}
      helpInLine={helpInLine}
      help={help}
      errorMessages={errorMessages}
      warningMessages={warningMessages}
      shouldUseFullWidth={true}
    >
      {readOnly ? renderReadOnlyInput() : renderEditableInput()}
    </InputField>
  );
}

AddressWithGemeenteParameters.propTypes = containerComponentProps;

export default AddressWithGemeenteParameters;

export function AddressWithGemeenteParametersForKotLabel(props) {
  return <AddressWithGemeenteParameters {...props} dossierType="kotlabel" />;
}

export function AddressWithGemeenteParametersForConformiteitsattest(props) {
  return (
    <AddressWithGemeenteParameters
      {...props}
      dossierType="conformiteitsattest"
    />
  );
}
