const defaultSize = 10;

const sortOptions = {
  "dossier.latestActivity": {
    order: "desc",
    nested: { path: "dossier" },
  },
};

const labelFields = ["dossier.label", "dossier.dossierDefinition.label"];

const statusFields = [
  "document.dossierinfoKL.status.selectedOptionLabel.nl",
  "document.dossierinfoCA.status.selectedOptionLabel.nl",
  "document.kotlabel.status.selectedOptionLabel.nl",
  "document.conformiteitsattest.status.selectedOptionLabel.nl",
];

const datumStatusFields = [
  "document.dossierinfoKL.datumStatus",
  "document.dossierinfoCA.datumStatus",
  "document.kotlabel.datumStatus",
  "document.conformiteitsattest.datumStatus",
];

const addressTextFields = [
  "document.aanvraagKotlabel.adresGebouw.straat",
  "document.aanvraagKotlabel.adresGebouw.gemeente",
  "document.aanvraagConformiteitsattest.adresGebouw.straat",
  "document.aanvraagConformiteitsattest.adresGebouw.gemeente",
  "document.kotlabel.adres.straat",
  "document.kotlabel.adres.gemeente",
  "document.conformiteitsattest.adres.straat",
  "document.conformiteitsattest.adres.gemeente",
];

const addressNumberFields = [
  "document.aanvraagKotlabel.adresGebouw.huisnummer",
  "document.aanvraagKotlabel.adresGebouw.busnummer",
  "document.aanvraagKotlabel.adresGebouw.postcode",
  "document.aanvraagConformiteitsattest.adresGebouw.huisnummer",
  "document.aanvraagConformiteitsattest.adresGebouw.busnummer",
  "document.aanvraagConformiteitsattest.adresGebouw.postcode",
  "document.kotlabel.adres.huisnummer",
  "document.kotlabel.adres.busnummer",
  "document.kotlabel.adres.postcode",
  "document.conformiteitsattest.adres.huisnummer",
  "document.conformiteitsattest.adres.busnummer",
  "document.conformiteitsattest.adres.postcode",
];

function createMultiMatchQuery(searchTerm, fields, exactMatch = false) {
  return {
    multi_match: {
      query: searchTerm,
      fields: fields,
      type: exactMatch ? "phrase" : "phrase_prefix",
    },
  };
}

function fullQuery(searchTerm) {
  return [
    createMultiMatchQuery(
      searchTerm,
      labelFields.concat(statusFields, datumStatusFields, addressTextFields),
    ),
    taskQuery(searchTerm),
    createMultiMatchQuery(searchTerm, addressNumberFields, true),
  ];
}

function taskQuery(searchTerm) {
  return {
    nested: {
      path: "task",
      query: {
        bool: {
          must: [
            createMultiMatchQuery(searchTerm, ["task.name"]),
            {
              term: {
                "task.active": true,
              },
            },
          ],
        },
      },
    },
  };
}

function mapSearchTermToAddressQuery(searchTerm) {
  searchTerm = searchTerm.replace(",", "");
  if (!searchTerm.match(/[0-9]+/)) {
    return undefined;
  }

  let searchTerms = searchTerm.split(" ");
  searchTerms = searchTerms.filter((term) => term !== "bus");
  const wordRegex = /^[A-zÀ-ü-]+$/;
  const textSearchTerms = [];
  const numberSearchTerms = [];

  let textIndex = 0;
  let isPreviousWord = true;

  searchTerms.forEach((term) => {
    if (term.match(wordRegex)) {
      if (isPreviousWord) {
        if (textSearchTerms[textIndex]) {
          textSearchTerms[textIndex] = `${textSearchTerms[textIndex]} ${term}`;
        } else {
          textSearchTerms.push(term);
        }
      } else {
        textSearchTerms.push(term);
        textIndex++;
      }
      isPreviousWord = true;
    } else {
      numberSearchTerms.push(term);
      isPreviousWord = false;
    }
  });

  return {
    bool: {
      must: [
        ...textSearchTerms.map((textSearchTerm) =>
          createMultiMatchQuery(textSearchTerm, addressTextFields),
        ),
        ...numberSearchTerms.map((numberSearchTerm) =>
          createMultiMatchQuery(numberSearchTerm, addressNumberFields, true),
        ),
      ],
    },
  };
}

function mapSearchTermToDate(searchTerm) {
  const dateRegex = /^[0-9]{1,4}-([0-9]{1,2}-?([0-9]{1,4})?)?$/g;
  const reversedateRegex = /^[0-9]{2}-([0-9]{1,2}-?([0-9]{1,4})?)?$/g;
  const date = searchTerm.match(dateRegex);
  const reversedate = searchTerm.match(reversedateRegex);

  if (date || reversedate) {
    if (reversedate) {
      return searchTerm
        .split("-")
        .reverse()
        .map((date) => `${date}*`)
        .join("-");
    }

    return searchTerm;
  }

  return undefined;
}

function mapSearchTermToQuery(searchTerm) {
  searchTerm = searchTerm.trim();
  searchTerm = searchTerm.replace(",", "");
  const query = [];

  if (searchTerm.includes(" ")) {
    const addressQuery = mapSearchTermToAddressQuery(searchTerm);
    if (addressQuery) {
      query.push(addressQuery);
    } else {
      query.push(createMultiMatchQuery(searchTerm, statusFields));
      query.push(createMultiMatchQuery(searchTerm, addressTextFields));
      query.push(taskQuery(searchTerm));
    }
  } else {
    const dateSearchTerm = mapSearchTermToDate(searchTerm);
    if (dateSearchTerm) {
      query.push(
        ...datumStatusFields.map((field) => ({
          wildcard: { [field]: { value: `*${dateSearchTerm}` } },
        })),
      );
    } else {
      query.push(...fullQuery(searchTerm));
    }
  }

  return query;
}

export function filterDashboardDossiersWithAdditionalColumns(
  searchTerm,
  pageNumber = 0,
  itemsPerPage = defaultSize,
  dossierTypes,
) {
  const query = createDossierSearchQuery(
    searchTerm,
    pageNumber,
    itemsPerPage,
    dossierTypes,
  );
  return fetchDossiersWithAdditionalColumns({ listKey: "DASHBOARD", query });
}

export function createDossierSearchQuery(
  searchTerm,
  pageNumber,
  itemsPerPage,
  dossierTypes = [],
) {
  return {
    query: {
      bool: {
        filter: [
          searchTerm
            ? {
                bool: {
                  must: [
                    {
                      query_string: {
                        query:
                          dossierTypes.length > 0
                            ? dossierTypes.join(" OR ")
                            : "**",
                        fields: ["dossier.dossierDefinition.key"],
                      },
                    },
                    { term: { type: "dossier" } },
                  ],
                  should: [...mapSearchTermToQuery(searchTerm)],
                },
              }
            : {
                bool: {
                  must: [
                    {
                      query_string: {
                        query:
                          dossierTypes.length > 0
                            ? dossierTypes.join(" OR ")
                            : "**",
                        fields: ["dossier.dossierDefinition.key"],
                      },
                    },
                    { term: { type: "dossier" } },
                  ],
                },
              },
        ],
      },
    },
    from: pageNumber * itemsPerPage,
    size: itemsPerPage,
    sort: sortOptions,
  };
}

export function fetchDossiersWithAdditionalColumns({ query, listKey } = {}) {
  if (!query) {
    query = {
      from: 0,
      query: {
        bool: {
          filter: [{ bool: { should: [{ term: { type: "dossier" } }] } }],
        },
      },
      size: defaultSize,
      sort: {
        "dossier.latestActivity": {
          order: "desc",
          nested: { path: "dossier" },
        },
      },
    };
  }
  return {
    type: "FETCH_SEARCH_VLOK_DOSSIERS",
    listKey,
    api: {
      url: "api/vlok-dossiers/search",
      method: "POST",
      listKey,
      data: query,
    },
  };
}
