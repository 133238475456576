import React, { useContext, useEffect, useState } from "react";
import { get, map } from "lodash";
import moment from "moment";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  defaultProps,
  dossierPageWrapper as coreDossierWrapper,
  externalProps,
  internalProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import Notification from "@skryv/core-react-vo/src/components/base/Notification/Notification";

import Title from "@skryv/core-react-vo/src/components/base/Title/Title";
import FileDisplay from "@skryv/core-react-vo/src/components/base/FileDisplay/FileDisplay";
import PageContent from "@skryv/core-react-vo/src/components/layout/Content/Content";

import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";

import ActiveTasks from "@skryv/core-react-vo/src/components/dossier/ActiveTasks/ActiveTasks";
import CustomInfoBlock from "./util/CustomInfoBlock";
import CustomTable from "./util/CustomTable";
import { getSubdossiersFromHoofddossier } from "../services/hoofddossier";
import { dossierStatus } from "../constants/dossierStatus";
import {
  createDossierInformationItem,
  createDossierInformationNestedItem,
} from "../util/createDossierInformationUtil";
import { retrieveContactInformatieFromGemeente } from "../services/gemeenteparameter";
import CustomContact from "./util/CustomContact";
import { Alert } from "./util/Alert";
import { DossierSectionLayout } from "./util/DossierSectionLayout";
import PropTypes from "prop-types";
import { subdossierTableColumns } from "../constants/subdossierTableColumns";

export function flDossierWrapper() {
  function FlDossier(props) {
    const context = useContext(contextType);
    const [subdossiersPage, setSubdossiersPage] = useState(undefined);
    const [contactInformation, setContactInformation] = useState(undefined);
    const dossierId = props.dossier.id;
    const { dossierType, getFieldFromDocument } = props;
    const columns = subdossierTableColumns[dossierType];

    useEffect(() => {
      if (!subdossiersPage) {
        const formulier =
          dossierType === "aanvraagKotlabel"
            ? "dossierinfoKL"
            : "dossierinfoCA";
        getSubdossiersFromHoofddossier(dossierId, formulier)
          .then((page) => setSubdossiersPage(page))
          .catch(() => setSubdossiersPage(undefined));
      }

      if (!contactInformation) {
        const niscode = getFieldFromDocument(dossierType, [
          "adresGebouw",
          "nisCode",
        ]);
        retrieveContactInformatieFromGemeente(niscode, dossierType)
          .then((contactInfo) =>
            setContactInformation({
              title: contactInfo.dienst,
              address: {
                streetAndNumber: mapStreetAndHousenumber(
                  contactInfo.adres.straat,
                  contactInfo.adres.huisnummer,
                ),
                zipCode: contactInfo.adres.postcode,
                city: contactInfo.adres.gemeente,
              },
              phone: contactInfo.contact.telefoonnummer,
              email: contactInfo.contact.emailadres,
              website: contactInfo.contact.website,
            }),
          )
          .catch(() => setContactInformation(undefined));
      }
    }, [
      dossierId,
      dossierType,
      subdossiersPage,
      contactInformation,
      getFieldFromDocument,
    ]);

    const activeTasks = props.getMyActiveTasksInDossier();
    const aanvraagSubmitted =
      props.dossierDetails.milestones &&
      props.dossierDetails.milestones.length > 0 &&
      props.dossierDetails.milestones.some(
        (milestone) => milestone.key === `${dossierType}Ingediend`,
      );

    if (!aanvraagSubmitted) {
      return (
        <PageContent contentName="dossier">
          <div className="dossier-page-header">
            <div className="dossier-page-header-title">
              {props.shouldShowTitle && (
                <div className="dossier-title vl-col--1-1">
                  <Title level={1} title={props.dossier.label} />
                </div>
              )}
            </div>
          </div>
          {props.shouldShowActiveTasks &&
            activeTasks &&
            activeTasks.length > 0 && (
              <div className="dossier-active-tasks vl-col--1-1">
                <ActiveTasks
                  activeTasks={activeTasks}
                  execute={props.executeTask}
                />
              </div>
            )}
        </PageContent>
      );
    }

    const contentWithMappedStatus = map(
      get(subdossiersPage, ["content"], []),
      (subdossier) => {
        return { ...subdossier, status: dossierStatus[subdossier.status] };
      },
    );
    const dossierInformation = getDossierInformation(
      props,
      context,
      dossierType,
    );
    const notifications = getNotifications(props, context, dossierType).filter(
      (notif) => notif !== undefined,
    );
    const downloads = getDownloads(props);

    return (
      <PageContent contentName="dossier">
        <div className="dossier-page-header">
          <div className="dossier-page-header-title">
            {props.shouldShowTitle && (
              <div className="dossier-title vl-col--1-1">
                <Title level={1} title={props.dossier.label} />
              </div>
            )}
          </div>
        </div>
        {props.shouldShowNotifications && (
          <div className="dossier-notifications vl-col--1-1">
            {map(notifications, (notification, key) => (
              <Notification
                notification={notification}
                class="dossier-notification"
                key={key}
              />
            ))}
          </div>
        )}
        {props.shouldShowActiveTasks &&
          activeTasks &&
          activeTasks.length > 0 && (
            <div className="dossier-active-tasks vl-col--1-1">
              <ActiveTasks
                activeTasks={activeTasks}
                execute={props.executeTask}
              />
            </div>
          )}
        {props.shouldShowDossierInformation &&
          dossierInformation &&
          dossierInformation.length > 0 && (
            <DossierSectionLayout
              icon="news"
              title="Dossier details"
              type="information"
            >
              <CustomInfoBlock infoBlockData={dossierInformation} />
            </DossierSectionLayout>
          )}
        {props.shouldShowDownloads && downloads && downloads.length > 0 && (
          <DossierSectionLayout
            icon="file-download"
            title="Documents and Downloads"
            type="downloads"
          >
            <FileDisplay downloads={downloads} />
          </DossierSectionLayout>
        )}
        {props.shouldShowDossierInformation && (
          <DossierSectionLayout
            icon="news"
            title={`${dossierType === "aanvraagKotlabel" ? "Behandelde koten" : "Behandelde woningen"}`}
            type="information"
          >
            {subdossiersPage &&
            subdossiersPage.content &&
            subdossiersPage.content.length > 0 ? (
              <CustomTable
                itemClickAction={(dossier) => props.toDossier(dossier.id)}
                tableData={contentWithMappedStatus}
                tableColumns={columns}
                pageable={subdossiersPage.pageable}
                size={subdossiersPage.size}
                totalPages={subdossiersPage.totalPages}
                hoofddossierId={props.dossier.id}
              />
            ) : (
              <Alert
                title="Info"
                message={`Van zodra de gemeente een ${dossierType === "aanvraagKotlabel" ? "kotlabel" : "conformiteitsattest"} uitreikt of weigert, vindt u hier de details van de behandelde ${dossierType === "aanvraagKotlabel" ? "koten" : "woningen"}.`}
              />
            )}
          </DossierSectionLayout>
        )}
        {props.shouldShowContactInformation && contactInformation && (
          <DossierSectionLayout icon="chat" title="Contact" type="contact">
            <CustomContact contactInformation={contactInformation} />
          </DossierSectionLayout>
        )}
      </PageContent>
    );
  }

  FlDossier.propTypes = {
    ...externalProps,
    ...internalProps,
    dossierType: PropTypes.oneOf(["kotlabel", "conformiteitsattest"])
      .isRequired,
  };
  FlDossier.defaultProps = defaultProps;

  return FlDossier;
}

const getContactInfo = (getFieldFromDocument, context, dossierType) => {
  const email = getFieldFromDocument(dossierType, ["emailadresAanvrager"]);
  const phone = getFieldFromDocument(dossierType, ["telefoonnummerAanvrager"]);
  const contactInfoNestedValues = [];

  contactInfoNestedValues.push(
    createDossierInformationNestedItem(email, context.gettext("Email"), true),
  );

  if (phone) {
    contactInfoNestedValues.push(
      createDossierInformationNestedItem(
        phone,
        context.gettext("Telefoon"),
        true,
      ),
    );
  }

  return createDossierInformationItem(
    contactInfoNestedValues,
    context.gettext("Contactgegevens"),
    true,
  );
};

const getAddress = (getFieldFromDocument, context, dossierType) => {
  const street = getFieldFromDocument(dossierType, ["adresGebouw", "straat"]);
  const housenumber = getFieldFromDocument(dossierType, [
    "adresGebouw",
    "huisnummer",
  ]);
  const busnumber = getFieldFromDocument(dossierType, [
    "adresGebouw",
    "busnummer",
  ]);
  const postalCode = getFieldFromDocument(dossierType, [
    "adresGebouw",
    "postcode",
  ]);
  const municipality = getFieldFromDocument(dossierType, [
    "adresGebouw",
    "gemeente",
  ]);
  const addressValue = `${street} ${housenumber} ${busnumber ? ` bus ${busnumber}` : ""}, ${postalCode} ${municipality}`;

  return createDossierInformationItem(addressValue, context.gettext("Adres"));
};

const getStatus = (getFieldFromDocument, context, document) => {
  const status = getFieldFromDocument(document, ["status", "selectedOption"]);
  const statusDate = getFieldFromDocument(document, ["datumStatus"]);
  const redenStopgezet = getFieldFromDocument(document, ["redenStatusUpdate"]);

  if (!status || !statusDate) return undefined;

  const statusArray = [
    createDossierInformationItem(
      dossierStatus[status],
      context.gettext("Status"),
    ),
    createDossierInformationItem(
      moment(moment.utc(statusDate).toDate()).format("DD-MM-YYYY"),
      context.gettext("Aangepast op"),
    ),
  ];

  if (status === "stopgezet" && !!redenStopgezet) {
    statusArray.push(
      createDossierInformationItem(
        redenStopgezet,
        context.gettext("Reden voor stopzetting"),
      ),
    );
  }

  return statusArray;
};

const getContactPersoon = (getFieldFromDocument, context, dossierType) => {
  const contactPersoon = getFieldFromDocument(dossierType, [
    "contactpersoonOnderneming",
  ]);

  return createDossierInformationItem(
    contactPersoon,
    context.gettext("Contactpersoon onderneming"),
  );
};

const getDossierInformation = (props, context, dossierType) => {
  const dossierInformation = [];

  if (!props.dossier) return dossierInformation;

  const document =
    dossierType === "aanvraagKotlabel" ? "dossierinfoKL" : "dossierinfoCA";
  const status = getStatus(props.getFieldFromDocument, context, document);
  status && dossierInformation.push(...status);
  dossierInformation.push({
    value: get(props.dossierDetails.dossier.creatorDetails, "username"),
    label: context.gettext("Aanvrager"),
    nested: false,
  });
  dossierInformation.push(
    getContactInfo(props.getFieldFromDocument, context, dossierType),
  );
  dossierInformation.push(
    getAddress(props.getFieldFromDocument, context, dossierType),
  );
  const contactPersoon = getContactPersoon(
    props.getFieldFromDocument,
    context,
    dossierType,
  );
  if (contactPersoon.value) {
    dossierInformation.push(contactPersoon);
  }

  return dossierInformation;
};

const getDownloads = (props) => {
  const downloads = [];
  if (props.downloads.length > 0) {
    for (let i = 0; i < props.downloadItems.length; i++) {
      downloads.push(props.downloads[i]);
    }
  }
  return downloads;
};

const getNotifications = (props, context, dossierType) => {
  const document =
    dossierType === "aanvraagKotlabel" ? "dossierinfoKL" : "dossierinfoCA";
  const status = props.getFieldFromDocument(document, [
    "status",
    "selectedOption",
  ]);

  let notification = {
    id: props.dossier.id,
    message: "",
    title: context.gettext(dossierStatus[status]),
    type: notificationTypes.SUCCESS,
  };

  switch (status) {
    case "aanvraagIngediend":
      notification.message = context.gettext(
        `We hebben uw aanvraag goed ontvangen op ${moment(get(props.dossierDetails.dossier, "createdAt")).format("DD-MM-YYYY")} en bekijken of uw dossier volledig is.`,
      );
      break;
    case "inBehandeling":
      notification.message = context.gettext(
        `We onderzoeken momenteel uw aanvraag voor het bekomen van het Vlaams kotlabel.`,
      );
      break;
    case "afgerond":
      notification.message = context.gettext(
        `We hebben uw aanvraag afgerond. Klik op het kotlabel dossier om de beoordeling van uw aanvraag te raadplegen.`,
      );
      break;
    case "stopgezet":
      notification.message = context.gettext(
        `We hebben de beoordeling van uw aanvraag stopgezet. U kan contact opnemen met de gemeente indien u niet akkoord bent met de hieronder vermelde reden van stopzetting.`,
      );
      notification.type = notificationTypes.ERROR;
      break;
    default:
      notification = undefined;
  }

  return [notification];
};

const mapStreetAndHousenumber = (street, housenumber) => {
  if (!street && !housenumber) return undefined;

  const straat = street ? `${street} ` : "";
  const huisnummer = housenumber ? housenumber : "";

  return `${straat}${huisnummer}`;
};

export default coreDossierWrapper(flDossierWrapper());
