import moment from "moment";
import { filter, get, map, valuesIn, find } from "lodash";
import dossierDefinitionKeys from "../constants/dossierDefinitionKeys";
import {
  SHOW_DTL_DOSSIER_BUTTON,
  SHOW_GV_DOSSIER_BUTTON,
  SHOW_HL_DOSSIER_BUTTON,
  SHOW_HP_DOSSIER_BUTTON,
  SHOW_HP_WACHTTIJD_BUTTON,
  SHOW_HS_DOSSIER_BUTTON,
  SHOW_VGWVHR_DOSSIER_BUTTON,
  SHOW_VLOK_DOSSIER_BUTTONS,
} from "../constants/featureFlags";

export function dossierDefinitionsToCallToAction(
  dossierDefinitions,
  dossierDefinitionKeysWhitelist,
) {
  const dossierDefinitionsAsArray = valuesIn(dossierDefinitions);
  const whitelistedDossierDefinitions = filter(
    dossierDefinitionsAsArray,
    (dossierDefinition) => {
      return dossierDefinitionKeysWhitelist.indexOf(dossierDefinition.key) > -1;
    },
  );
  const dossierCreateButtons = map(
    whitelistedDossierDefinitions,
    (dossierDefinition) => {
      const creationModes = get(dossierDefinition, ["data", "creationModes"]);
      return {
        dosdefKey: get(dossierDefinition, ["key"]),
        label: get(dossierDefinition, ["label"]),
        description: get(dossierDefinition, ["data", "description"]),
        startButtonLabel: get(dossierDefinition, ["data", "startButtonLabel"]),
        isCreationModeEloket: Boolean(find(creationModes, { key: "eLoket" })),
      };
    },
  );
  return filter(dossierCreateButtons, "isCreationModeEloket");
}

export function isNoodwoningenActive() {
  const currentMonth = moment().month() + 1;
  const currentYear = moment().year();
  return currentMonth < 5 && currentYear < 2025;
}

export function getWhitelistedDossierDefinitionsForCompany() {
  if (!SHOW_VLOK_DOSSIER_BUTTONS) {
    return [];
  }

  return [
    dossierDefinitionKeys.AKL,
    dossierDefinitionKeys.KL,
    dossierDefinitionKeys.ACA,
    dossierDefinitionKeys.CA,
  ];
}

export function getWhitelistedDossierDefinitionsForCitizens() {
  const whitelistedDossierDefinitions = [];

  if (SHOW_HP_DOSSIER_BUTTON) {
    whitelistedDossierDefinitions.push(dossierDefinitionKeys.HP);
  }

  if (SHOW_HP_WACHTTIJD_BUTTON) {
    whitelistedDossierDefinitions.push(dossierDefinitionKeys.HPWT);
  }

  if (SHOW_HS_DOSSIER_BUTTON) {
    whitelistedDossierDefinitions.push(dossierDefinitionKeys.HS);
  }

  if (SHOW_GV_DOSSIER_BUTTON) {
    whitelistedDossierDefinitions.push(dossierDefinitionKeys.GV);
  }

  if (SHOW_HL_DOSSIER_BUTTON) {
    whitelistedDossierDefinitions.push(dossierDefinitionKeys.HL);
  }

  if (SHOW_VGWVHR_DOSSIER_BUTTON) {
    whitelistedDossierDefinitions.push(dossierDefinitionKeys.VGWVHR);
  }

  if (SHOW_VLOK_DOSSIER_BUTTONS) {
    whitelistedDossierDefinitions.push(
      dossierDefinitionKeys.AKL,
      dossierDefinitionKeys.ACA,
      dossierDefinitionKeys.CA,
      dossierDefinitionKeys.KL,
    );
  }

  return whitelistedDossierDefinitions;
}

export function getWhitelistedDossierDefinitionsForLokaleBesturen() {
  const whitelistedDossiers = [];

  if (isNoodwoningenActive()) {
    whitelistedDossiers.push(dossierDefinitionKeys.NW);
  }

  if (SHOW_DTL_DOSSIER_BUTTON) {
    whitelistedDossiers.push(dossierDefinitionKeys.DTL);
  }

  return whitelistedDossiers;
}

export function getWhitelistedDossierDefinitionsForOCMW(
  canCreateDossierOCMW,
  canCreateOCMWDossiers,
) {
  const whitelistedDossiers = [];

  if (canCreateDossierOCMW) {
    whitelistedDossiers.push(dossierDefinitionKeys.FBU);
  }

  if (!canCreateOCMWDossiers) {
    return whitelistedDossiers;
  }

  if (isNoodwoningenActive()) {
    whitelistedDossiers.push(dossierDefinitionKeys.NW);
  }

  if (SHOW_DTL_DOSSIER_BUTTON) {
    whitelistedDossiers.push(dossierDefinitionKeys.DTL);
  }

  return whitelistedDossiers;
}
