export const subdossierTableColumns = {
  aanvraagKotlabel: [
    {
      text: "Dossier",
      key: "dossier",
    },
    {
      text: "Adres",
      key: "adres",
    },
    {
      text: "Verdieping",
      key: "verdiep",
    },
    {
      text: "Kamernummer",
      key: "kamernummer",
    },
    {
      text: "Locatie",
      key: "locatie",
    },
    {
      text: "Status",
      key: "status",
    },
  ],
  aanvraagConformiteitsattest: [
    {
      text: "Dossier",
      key: "dossier",
    },
    {
      text: "Adres",
      key: "adres",
    },
    {
      text: "Verdieping",
      key: "verdiep",
    },
    {
      text: "Locatie",
      key: "locatie",
    },
    {
      text: "Status",
      key: "status",
    },
  ],
};
