import React from "react";
import { get, isEmpty, map } from "lodash";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  dossierPageWrapper as coreDossierWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";
import Notification from "@skryv/core-react-vo/src/components/base/Notification/Notification";
import ActiveTasks from "@skryv/core-react-vo/src/components/dossier/ActiveTasks/ActiveTasks";
import Title from "@skryv/core-react-vo/src/components/base/Title/Title";
import contactInformation from "../constants/contactInformation";
import PageContent from "@skryv/core-react-vo/src/components/layout/Content/Content";
import DossierProgress from "@skryv/core-react-vo/src/components/dossier/DossierProgress/DossierProgress";
import Modal from "@skryv/core-react-vo/src/components/base/Modal/Modal";
import milestoneDefKeys from "../constants/milestoneDefinitionKeys"; // import milestone definition keys
import InfoBlock from "@skryv/core-react-vo/src/components/base/InfoBlock/InfoBlock";
import Contact from "@skryv/core-react-vo/src/components/base/Contact/Contact";
import FileDisplay from "@skryv/core-react-vo/src/components/base/FileDisplay/FileDisplay";

export function flDossierWrapper() {
  class flDossier extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    constructor(props) {
      super(props);

      // the contact information is static and will not change, we can set it here
      // the other state properties depend on the dossier state an will be set in the componentDidUpdate
      // for now we set them equal to the props, which contain the default values
      this.state = {
        contactInformation: {
          ...contactInformation,
          email: "huursubsidie.wonen@vlaanderen.be",
          title: "Wonen in Vlaanderen",
          address: {
            buildingName: "Dienst betaalbaar wonen – huursubsidie",
            streetAndNumber: "Koning Albert II-laan 15 bus 253",
            zipCode: "1210",
            city: "Brussel",
            country: "België",
          },
          lat: undefined,
          long: undefined,
          mapUrl: undefined,
          phone: 1700,
        },
        dossierInformation: this.props.dossierInformation,
        downloads: this.props.downloads,
        notifications: this.props.notifications,
      };

      this.getDossierInformation = this.getDossierInformation.bind(this);
      this.getDownloads = this.getDownloads.bind(this);
      this.getNotifications = this.getNotifications.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.props.dossierDetails !== prevProps.dossierDetails) {
        // the props have just been updated; so either a parent updated the props or new values came in through redux
        this.setState({
          dossierInformation: this.getDossierInformation(),
          downloads: this.getDownloads(),
          notifications: this.getNotifications(),
        });
      }
    }

    getDossierInformation() {
      const dossierInformation = [];
      if (this.props.dossier) {
        if (get(this.props.dossier, "externalId") !== undefined) {
          dossierInformation.push({
            value: get(this.props.dossier, "externalId"),
            label: this.context.gettext("Dossiernummer"),
          });
        }

        if (this.props.dossierDetails) {
          const remarks = this.props.getFieldFromDocument(
            "hsOpmerkingFormHidden",
            ["opmerkingen", "value"],
          );
          dossierInformation.push({
            value: remarks,
            label: "Opmerking",
          });
        }
      }
      return dossierInformation;
    }

    getDownloads() {
      const downloads = [];
      if (this.props.downloads.length > 0) {
        //      downloads.push(this.props.downloads[0]);
        for (let i = 0; i < this.props.downloadItems.length; i++) {
          downloads.push(this.props.downloads[i]);
        }
      }
      return downloads;
    }

    getNotifications() {
      const milestone_ontvangstbevestiging = this.props.getMilestone(
        milestoneDefKeys.HP_ONTVANGEN,
      );

      let notifications = [];

      if (milestone_ontvangstbevestiging) {
        notifications = [
          {
            id: this.props.dossier.id,
            message: this.context.gettext("Uw formulier werd ingediend."),
            title: this.context.gettext("Formulier ingediend"),
            type: notificationTypes.SUCCESS,
          },
        ];
      }

      return notifications;
    }

    render() {
      // override some default props with props that are derived from latest version of the dossierDetails
      const componentsToPass = {
        ...this.props,
        contactInformation: this.state.contactInformation,
        dossierInformation: this.state.dossierInformation,
        downloads: this.state.downloads,
        notifications: this.state.notifications,
      };

      return <HPDossierPage {...componentsToPass} />;
    }
  }

  return flDossier;
}

class HPDossierPage extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;
  static contextType = contextType;

  renderTitle() {
    const externalId = this.props.dossier.externalId;
    let title =
      externalId == undefined
        ? "Nieuwe Huursubsidie aanvraag"
        : "Huursubsidie dossier " + externalId;
    return (
      <div className="dossier-title vl-col--1-1">
        <Title level={1} title={title} />
      </div>
    );
  }

  renderDossierProgress() {
    const steps = this.props.getDossierProgressSteps();
    return steps && <DossierProgress steps={steps} />;
  }

  renderNotification() {
    return (
      this.props.notifications &&
      this.props.notifications.length > 0 && (
        <div className="dossier-notifications vl-col--1-1">
          {map(this.props.notifications, (notification, key) => (
            <Notification
              notification={notification}
              class="dossier-notification"
              key={key}
            />
          ))}
        </div>
      )
    );
  }

  renderActiveTasks() {
    if (this.props.loadingDossierDetails) {
      return (
        <p>{this.context.gettext("Loading new dossier information...")}</p>
      );
    }

    const activeTasks = this.props.getMyActiveTasksInDossier();
    return (
      activeTasks &&
      activeTasks.length > 0 && (
        <div className="dossier-active-tasks vl-col--1-1">
          <ActiveTasks
            activeTasks={activeTasks}
            execute={this.props.executeTask}
          />
        </div>
      )
    );
  }

  renderEnabledTasks() {
    const { enabledTasks } = this.props;

    if (!enabledTasks || isEmpty(enabledTasks)) return null;

    function mapActivityToTask(activity) {
      return {
        id: activity.id,
        name: activity.activityName,
        description: activity.activityDescription,
      };
    }

    const mappedEnabledTasks = map(enabledTasks, mapActivityToTask);

    return (
      <div className="dossier-title vl-col--1-1">
        <Title level={2} title="Dossier Acties" iconClass="vl-vi-cursor-hand" />
        <ActiveTasks
          activeTasks={mappedEnabledTasks}
          execute={this.props.handleEnabledTaskSelect}
        />
      </div>
    );
  }

  renderDossierInformation() {
    if (this.props.getLatestMilestoneKey() == "bevestigingMedebewoners") {
      const ondertekenaars = [];
      this.props
        .getFieldFromDocument("vervolledigHSAanvraag", ["ondertekenaars"])
        .elements.forEach((e) => ondertekenaars.push(e));
      const ondertekenaarUl = ondertekenaars.map((o) => (
        <li key={o.insz} style={{ listStyle: "initial", fontWeight: "bold" }}>
          {o.naam}
        </li>
      ));
      return (
        this.props.dossierInformation &&
        this.props.dossierInformation.length > 0 && (
          <div className="dossier-information vl-col--1-1">
            <div
              style={{
                backgroundColor: "#f7f9fc",
                padding: 40,
                borderRadius: 3,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#e8ebee",
              }}
            >
              <p>
                <em style={{ fontWeight: "bold" }}>Opgelet:</em> meerderjarige
                gezinsleden die op uw adres wonen, moeten deze aanvraag ook mee
                ondertekenen.
              </p>
              <br></br>
              <p>Het gaat om deze personen:</p>
              <ul>{ondertekenaarUl}</ul>
              <br></br>
              <p>
                Voer de volgende stappen uit om de aanvraag te vervolledigen:
              </p>
              <br></br>
              <ol>
                <li style={{ listStyle: "-moz-initial" }}>
                  Meld u eerst zelf af van dit Formulierenloket. Dat kan via het
                  'Mijn Burgerprofiel' menu rechts bovenaan.
                </li>
                <li style={{ listStyle: "-moz-initial" }}>
                  De medebewoner meldt zich daarna aan op het Formulierenloket
                  via hetzelfde 'Mijn Burgerprofiel' menu.
                </li>
                <li style={{ listStyle: "-moz-initial" }}>
                  Het Formulierenloket opent. De medebewoner ziet de aanvraag in
                  het overzicht bij 'Mijn dossiers'. Naast de aanvraag ziet de
                  medebewoner de status 'Medebewoner moet bevestigen'.
                </li>
                <li style={{ listStyle: "-moz-initial" }}>
                  De medebewoner klikt op de knop 'Onderteken de aanvraag'.
                </li>
                <li style={{ listStyle: "-moz-initial" }}>
                  De aanvraag opent rechtstreeks op de pagina waar de
                  medebewoner deze kan bevestigen. De medebewoner kan op de
                  pagina eventueel de documenten raadplegen door ze te
                  downloaden en te openen.
                </li>
                <li style={{ listStyle: "-moz-initial" }}>
                  Is de aanvraag voor de medebewoner in orde? Dan ondertekent de
                  medebewoner de aanvraag door op de knop 'Uitvoeren' te
                  klikken.
                </li>
                <li style={{ listStyle: "-moz-initial" }}>
                  De medebewoner dient daarna de aanvraag in door op de knop
                  'indienen' te klikken.
                </li>
                <li style={{ listStyle: "-moz-initial" }}>
                  Moeten er meerdere medebewoners ondertekenen? Dan voeren alle
                  medebewoners de stappen 2 tot en met 7 uit. Hebben alle
                  medebewoners getekend? Controleer dan bij het veld
                  'Opmerkingen' of de status van de aanvraag wijzigde naar 'Uw
                  aanvraag is ingediend'. Als dat de status is, dan heeft u de
                  aanvraag correct ingediend.
                </li>
              </ol>
              <br></br>
              <p>
                Bij problemen of vragen contacteert u de helpdesk via de knop
                'Hulp nodig' rechts bovenaan. U vindt daar e-mailgegevens.
              </p>
            </div>
          </div>
        )
      );
    } else {
      return (
        this.props.dossierInformation &&
        this.props.dossierInformation.length > 0 && (
          <div className="dossier-information vl-col--1-1">
            <section className="vl-infoblock">
              <Title
                level={2}
                title={"Overzicht van uw aanvraag"}
                iconClass="vl-vi-news"
              />
              <div
                style={{
                  backgroundColor: "#f7f9fc",
                  padding: 40,
                  borderRadius: 3,
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#e8ebee",
                }}
              >
                <InfoBlock
                  infoBlockData={this.props.dossierInformation}
                  columnsNumber={this.props.dossierInformationNbColumns}
                />
              </div>
            </section>
          </div>
        )
      );
    }
  }

  renderDownloads() {
    return (
      this.props.downloads &&
      this.props.downloads.length > 0 && (
        <div className="dossier-downloads vl-col--1-1">
          <section className="vl-infoblock">
            <Title
              level={2}
              title={"Download uw document(en)"}
              iconClass="vl-vi-file-download"
            />
            <p>
              Opgelet: medebewoners kunnen enkel raadplegen maar geen gegevens
              bewerken. Dat kan enkel de originele aanvrager.
            </p>
            <FileDisplay downloads={this.props.downloads} />
          </section>
        </div>
      )
    );
  }

  renderContactInformation() {
    return (
      this.props.contactInformation && (
        <div className="dossier-contact vl-col--1-1">
          <section className="vl-infoblock">
            <Contact contactInformation={this.props.contactInformation} />
          </section>
        </div>
      )
    );
  }

  renderActivateTaskWarningModal() {
    return (
      <Modal
        open={this.props.shouldShowActivateTaskWarningModal}
        text={this.context.gettext(
          "Ben je zeker dat je deze actie wilt uitvoeren?",
        )}
        title={`${this.context.gettext("Voer")} ${(
          this.props.modalTask.activityName || ""
        ).toLowerCase()} ${this.context.gettext("uit")}`}
        yesText={this.context.gettext("Yes")}
        noText={this.context.gettext("No")}
        yes={() => this.props.toEnabledTask(this.props.modalTask.id)}
        no={() => this.props.cancelActivationOfEnabledTask()}
        modalIdentifier={this.props.modalTask.id || "default"}
      />
    );
  }

  render() {
    return (
      <PageContent contentName="dossier">
        {this.renderDossierProgress()}
        <div className="dossier-page-header">
          <div className="dossier-page-header-title">
            {this.props.shouldShowTitle && this.renderTitle()}
          </div>
        </div>
        {this.renderActivateTaskWarningModal()}
        {this.props.shouldShowNotifications && this.renderNotification()}
        {this.props.shouldShowActiveTasks && this.renderActiveTasks()}
        {this.props.shouldShowDossierInformation &&
          this.renderDossierInformation()}
        {this.props.shouldShowDownloads && this.renderDownloads()}
        {this.renderEnabledTasks()}
        {this.props.shouldShowContactInformation &&
          this.renderContactInformation()}
      </PageContent>
    );
  }
}

export default coreDossierWrapper(flDossierWrapper());
